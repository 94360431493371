<template>
  <v-dialog v-model="showDialog" persistent>
    <v-card>
      <v-card-title class="justify-center white--text next mb-4">{{
        previewDescription
      }}</v-card-title>
      <div class="cropped-image mt-3" :max-width="600">
        <v-img
          class="mx-auto"
          v-if="previewImage"
          :src="previewImage"
          width="100%"
          max-width="600px"
        ></v-img>
        <div v-else class="crop-placeholder" />
      </div>
      <v-card-actions class="justify-center pb-5">
        <v-btn
          class="font-weight-black"
          align="center"
          width="15vw"
          max-width="300px"
          color="next"
          style="font-size:20px"
          dark
          @click="closePreview()"
          >{{ $t('button.close') }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { splitMessageLine } from '@/lib/commonUtil';

export default {
  props: {
    showDialog: Boolean,
    closePreview: Function,
    previewDescription: String,
    previewImage: String,
  },
  data: () => ({}),
  methods: {
    splitMessageLine(message) {
      return splitMessageLine(message);
    },
  },
};
</script>
