var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "" },
      model: {
        value: _vm.showDialog,
        callback: function($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "justify-center white--text next mb-4" },
            [_vm._v(_vm._s(_vm.previewDescription))]
          ),
          _c(
            "div",
            { staticClass: "cropped-image mt-3", attrs: { "max-width": 600 } },
            [
              _vm.previewImage
                ? _c("v-img", {
                    staticClass: "mx-auto",
                    attrs: {
                      src: _vm.previewImage,
                      width: "100%",
                      "max-width": "600px"
                    }
                  })
                : _c("div", { staticClass: "crop-placeholder" })
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "justify-center pb-5" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "font-weight-black",
                  staticStyle: { "font-size": "20px" },
                  attrs: {
                    align: "center",
                    width: "15vw",
                    "max-width": "300px",
                    color: "next",
                    dark: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.closePreview()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("button.close")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }