var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "" },
      model: {
        value: _vm.showDialog,
        callback: function($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "justify-center white--text  next" },
            [_vm._v(_vm._s(_vm.$t("title.agency.highLightList")))]
          ),
          _vm.order
            ? [
                _c("v-card-text", [
                  _c(
                    "p",
                    {
                      staticClass: "mb-0",
                      staticStyle: { "white-space": "pre-line" }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t("description.highLightList.explanation1")
                          ) +
                          "\n          " +
                          _vm._s(
                            _vm.$t("description.highLightList.explanation2")
                          ) +
                          "\n        "
                      )
                    ]
                  )
                ]),
                _c(
                  "v-container",
                  {
                    staticClass: "overflow-y-auto",
                    staticStyle: { "max-height": "400px", "max-width": "100%" },
                    attrs: { id: "scroll-target" }
                  },
                  [
                    _vm.changeCoordinateManagementListData.length <= 0
                      ? [
                          _c("p", {
                            staticClass: "mb-0",
                            staticStyle: { "white-space": "pre-line" }
                          }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("label.nonHighLightList")) +
                              "\n        "
                          )
                        ]
                      : _vm._e(),
                    _c(
                      "ol",
                      [
                        _c(
                          "vuedraggable",
                          {
                            attrs: { options: { animation: 150 } },
                            on: { end: _vm.onEnd }
                          },
                          _vm._l(_vm.coordinateManagementList, function(
                            item,
                            index
                          ) {
                            return _c(
                              "li",
                              { key: index, staticClass: "vuedraggable_list" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(item.description) +
                                    "\n              (" +
                                    _vm._s(_vm.$t("label.oldScreenPage")) +
                                    ":" +
                                    _vm._s(item.screenPage) +
                                    ")\n            "
                                )
                              ]
                            )
                          }),
                          0
                        )
                      ],
                      1
                    )
                  ],
                  2
                ),
                _c(
                  "v-card-actions",
                  { staticClass: "justify-center pb-5" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "font-weight-black",
                        staticStyle: { "font-size": "16px" },
                        attrs: {
                          align: "center",
                          width: "15vw",
                          "max-width": "300px",
                          color: "next",
                          dark: ""
                        },
                        on: {
                          click: function($event) {
                            return _vm.cancelReorder()
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                    ),
                    _c("v-flex", { attrs: { md1: "" } }),
                    _c(
                      "v-btn",
                      {
                        staticClass: "font-weight-black",
                        staticStyle: { "font-size": "16px" },
                        attrs: {
                          align: "center",
                          width: "15vw",
                          "max-width": "300px",
                          color: "next",
                          dark: ""
                        },
                        on: {
                          click: function($event) {
                            return _vm.completeReorder()
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("button.update")))]
                    )
                  ],
                  1
                )
              ]
            : [
                _c("v-data-table", {
                  staticClass: "elevation-1 ma-3",
                  attrs: {
                    "calculate-widths": "",
                    headers: _vm.headers,
                    items: _vm.coordinateManagementList,
                    "hide-default-footer": true,
                    "items-per-page": _vm.itemsPerPage,
                    "no-data-text": _vm.$t("error.highLightListNotFound")
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "item.edit",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c(
                              "v-menu",
                              {
                                attrs: { "offset-y": "", left: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                attrs: {
                                                  color: "red",
                                                  fab: "",
                                                  "x-small": "",
                                                  dark: ""
                                                }
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { color: "white" } },
                                                [_vm._v("edit")]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c(
                                  "v-list",
                                  { staticClass: "ma-0 pa-0" },
                                  [
                                    _c(
                                      "v-list-item",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.onClickPreview(item)
                                          }
                                        }
                                      },
                                      [
                                        _c("v-icon", [
                                          _vm._v("mdi-eye-outline")
                                        ]),
                                        _c(
                                          "v-list-item-action",
                                          {
                                            staticClass: "mx-0",
                                            attrs: { color: "next" }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("button.preview"))
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-item",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.onClickEdit(item)
                                          }
                                        }
                                      },
                                      [
                                        _c("v-icon", [_vm._v("edit")]),
                                        _c(
                                          "v-list-item-action",
                                          {
                                            staticClass: "mx-0",
                                            attrs: { color: "next" }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("button.edit"))
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-item",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.onClickDelete(item)
                                          }
                                        }
                                      },
                                      [
                                        _c("v-icon", [_vm._v("delete")]),
                                        _c(
                                          "v-list-item-action",
                                          {
                                            staticClass: "mx-0",
                                            attrs: { color: "next" }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("button.delete"))
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        }
                      },
                      {
                        key: "item.singlePlanConfirmation",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c("MultiLineBoldText", {
                              attrs: { value: item.singlePlanConfirmation }
                            })
                          ]
                        }
                      },
                      {
                        key: "item.multiplePlansConfirmation",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            item.multiplePlansConfirmation
                              ? [
                                  _c("MultiLineBoldText", {
                                    attrs: {
                                      value: item.multiplePlansConfirmation
                                    }
                                  })
                                ]
                              : [_vm._v("\n            -\n          ")]
                          ]
                        }
                      },
                      {
                        key: "item.planFlg",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.checkFlg(item.planFlg)) +
                                "\n        "
                            )
                          ]
                        }
                      },
                      {
                        key: "item.importantFlg",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.checkFlg(item.importantFlg)) +
                                "\n        "
                            )
                          ]
                        }
                      },
                      {
                        key: "item.skipFlg",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.checkFlg(item.skipFlg)) +
                                "\n        "
                            )
                          ]
                        }
                      },
                      {
                        key: "item.clauseFlg",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.checkFlg(item.clauseFlg)) +
                                "\n        "
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                }),
                _c(
                  "v-card-actions",
                  { staticClass: "justify-center pb-5" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "font-weight-black",
                        staticStyle: { "font-size": "20px" },
                        attrs: {
                          align: "center",
                          width: "15vw",
                          "max-width": "200px",
                          color: "next",
                          dark: ""
                        },
                        on: {
                          click: function($event) {
                            return _vm.closeHighLightListDialog()
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("button.close")))]
                    ),
                    _c("v-flex", { attrs: { md1: "" } }),
                    _c(
                      "v-btn",
                      {
                        staticClass: "font-weight-black",
                        staticStyle: { "font-size": "20px" },
                        attrs: {
                          align: "center",
                          width: "15vw",
                          "max-width": "200px",
                          color: "next",
                          dark: ""
                        },
                        on: {
                          click: function($event) {
                            return _vm.onClickReorder()
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("button.reorder")))]
                    ),
                    _vm.onClickAddHightLight
                      ? [
                          _c("v-flex", { attrs: { md1: "" } }),
                          _c(
                            "v-btn",
                            {
                              staticClass: "font-weight-black",
                              staticStyle: { "font-size": "20px" },
                              attrs: {
                                align: "center",
                                width: "15vw",
                                "max-width": "200px",
                                color: "next",
                                dark: _vm.listMaxLengthValidation,
                                disabled: !_vm.listMaxLengthValidation
                              },
                              on: {
                                click: function($event) {
                                  return _vm.onClickAddHightLight()
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("button.add")))]
                          )
                        ]
                      : _vm._e()
                  ],
                  2
                )
              ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }