<template>
  <v-dialog v-model="showDialog" persistent>
    <v-card>
      <v-card-title class="justify-center white--text  next">{{
        $t('title.agency.highLightList')
      }}</v-card-title>
      <template v-if="order">
        <v-card-text>
          <p class="mb-0" style="white-space: pre-line;">
            {{ $t('description.highLightList.explanation1') }}
            {{ $t('description.highLightList.explanation2') }}
          </p>
        </v-card-text>
        <v-container
          id="scroll-target"
          style="max-height: 400px; max-width: 100%;"
          class="overflow-y-auto"
        >
          <template v-if="changeCoordinateManagementListData.length <= 0">
            <p class="mb-0" style="white-space: pre-line;"></p>
            {{ $t('label.nonHighLightList') }}
          </template>
          <ol>
            <vuedraggable :options="{ animation: 150 }" @end="onEnd">
              <li
                v-for="(item, index) in coordinateManagementList"
                :key="index"
                class="vuedraggable_list"
              >
                {{ item.description }}
                ({{ $t('label.oldScreenPage') }}:{{ item.screenPage }})
              </li>
            </vuedraggable>
          </ol>
        </v-container>
        <v-card-actions class="justify-center pb-5">
          <!-- キャンセル -->
          <v-btn
            class="font-weight-black"
            align="center"
            width="15vw"
            max-width="300px"
            color="next"
            style="font-size:16px"
            dark
            @click="cancelReorder()"
            >{{ $t('button.cancel') }}</v-btn
          >
          <v-flex md1 />
          <!-- 編集完了 -->
          <v-btn
            class="font-weight-black"
            align="center"
            width="15vw"
            max-width="300px"
            color="next"
            style="font-size:16px"
            dark
            @click="completeReorder()"
            >{{ $t('button.update') }}</v-btn
          >
        </v-card-actions>
      </template>
      <template v-else>
        <v-data-table
          calculate-widths
          :headers="headers"
          :items="coordinateManagementList"
          :hide-default-footer="true"
          :items-per-page="itemsPerPage"
          class="elevation-1 ma-3"
          :no-data-text="$t('error.highLightListNotFound')"
        >
          <template v-slot:[`item.edit`]="{ item }" class="pa-0">
            <v-menu offset-y left>
              <template v-slot:activator="{ on }">
                <v-btn color="red" fab x-small dark v-on="on">
                  <v-icon color="white">edit</v-icon>
                </v-btn>
              </template>
              <v-list class="ma-0 pa-0">
                <v-list-item @click="onClickPreview(item)">
                  <v-icon>mdi-eye-outline</v-icon>
                  <v-list-item-action class="mx-0" color="next">{{
                    $t('button.preview')
                  }}</v-list-item-action>
                </v-list-item>
                <v-list-item @click="onClickEdit(item)">
                  <v-icon>edit</v-icon>
                  <v-list-item-action class="mx-0" color="next">{{
                    $t('button.edit')
                  }}</v-list-item-action>
                </v-list-item>
                <v-list-item @click="onClickDelete(item)">
                  <v-icon>delete</v-icon>
                  <v-list-item-action class="mx-0" color="next">{{
                    $t('button.delete')
                  }}</v-list-item-action>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template
            v-slot:[`item.singlePlanConfirmation`]="{ item }"
            class="pa-0"
          >
            <MultiLineBoldText :value="item.singlePlanConfirmation" />
          </template>
          <template
            v-slot:[`item.multiplePlansConfirmation`]="{ item }"
            class="pa-0"
          >
            <template v-if="item.multiplePlansConfirmation">
              <MultiLineBoldText :value="item.multiplePlansConfirmation" />
            </template>
            <template v-else>
              -
            </template>
          </template>
          <template v-slot:[`item.planFlg`]="{ item }" class="pa-0">
            {{ checkFlg(item.planFlg) }}
          </template>
          <template v-slot:[`item.importantFlg`]="{ item }" class="pa-0">
            {{ checkFlg(item.importantFlg) }}
          </template>
          <template v-slot:[`item.skipFlg`]="{ item }" class="pa-0">
            {{ checkFlg(item.skipFlg) }}
          </template>
          <template v-slot:[`item.clauseFlg`]="{ item }" class="pa-0">
            {{ checkFlg(item.clauseFlg) }}
          </template>
        </v-data-table>

        <v-card-actions class="justify-center pb-5">
          <v-btn
            class="font-weight-black"
            align="center"
            width="15vw"
            max-width="200px"
            color="next"
            style="font-size:20px"
            dark
            @click="closeHighLightListDialog()"
            >{{ $t('button.close') }}</v-btn
          >
          <v-flex md1 />
          <v-btn
            class="font-weight-black"
            align="center"
            width="15vw"
            max-width="200px"
            color="next"
            style="font-size:20px"
            dark
            @click="onClickReorder()"
            >{{ $t('button.reorder') }}</v-btn
          >
          <template v-if="onClickAddHightLight">
            <v-flex md1 />
            <v-btn
              class="font-weight-black"
              align="center"
              width="15vw"
              max-width="200px"
              color="next"
              style="font-size:20px"
              :dark="listMaxLengthValidation"
              :disabled="!listMaxLengthValidation"
              @click="onClickAddHightLight()"
              >{{ $t('button.add') }}</v-btn
            >
          </template>
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import vuedraggable from 'vuedraggable';
import { HightLightListTableHeader, MaxCount } from '@/lib/const';
import { splitMessageLine } from '@/lib/commonUtil';
import MultiLineBoldText from '@/components/organisms/common/MultiLineBoldText';

export default {
  components: { vuedraggable, MultiLineBoldText },
  props: {
    onEnd: Function,
    onClickReorder: Function,
    onClickPreview: Function,
    onClickEdit: Function,
    onClickDelete: Function,
    onClickAddHightLight: Function || null,
    closeHighLightListDialog: Function,
    coordinateManagementList: Array,
    changeCoordinateManagementListData: Array,
    cancelReorder: Function,
    completeReorder: Function,
    showDialog: Boolean,
    order: Boolean || false,
    type: String,
  },
  computed: {
    listMaxLengthValidation() {
      return this.coordinateManagementList.length < MaxCount.HighLight;
    },
  },
  data: () => ({
    headers: HightLightListTableHeader,
    itemsPerPage: MaxCount.HighLight,
  }),
  methods: {
    checkFlg(boolean) {
      return boolean ? '○' : '-';
    },
    splitMessageLine(message) {
      return splitMessageLine(message);
    },
  },
};
</script>

<style>
.v_data_table_fill_width {
  width: 100%;
}
thead.v-data-table-header {
  background-color: #ddebf7;
}
thead.v-data-table-header > tr > th {
  font-size: 14px !important;
  text-align: center !important;
  padding: 0;
  white-space: pre-wrap;
}
.v_footer_justify_evenly {
  justify-content: space-evenly;
}
.v-data-table-header__icon {
  /* テーブルのソートアイコンを常に表示 */
  opacity: 1 !important;
  color: rgba(0, 0, 0, 0.2) !important;
}
th.active > .v-data-table-header__icon {
  color: rgba(0, 0, 0, 1) !important;
}
.v-data-table td {
  word-break: break-all;
}
/* 1ページあたりの行数を IE 11 でも表示させる */
.v-data-footer__select .v-select {
  width: 54px !important;
}

.vuedraggable_list {
  border-bottom: 1px solid gray;
}
.vuedraggable_list:nth-child(odd) {
  background: #f5f5f5;
}
.v-data-table {
  overflow: hidden;
}
</style>
